@font-face {
  font-family: "Gilroy-Private";
  font-style: normal;
  font-weight: 400;
  src: url("/public/fonts/Gilroy/Gilroy-Light.ttf");
}

@font-face {
  font-family: "Gilroy-Private";
  font-style: normal;
  font-weight: 500;
  src: url("/public/fonts/Gilroy/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "Gilroy-Private";
  font-style: normal;
  font-weight: 600;
  src: url("/public/fonts/Gilroy/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "Gilroy-Private";
  font-style: normal;
  font-weight: 700;
  src: url("/public/fonts/Gilroy/Gilroy-ExtraBold.ttf");
}

html,
body {
  max-width: 2560px;
  margin: 0 auto;
  background: black;
}

body {
  overflow-x: hidden;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(162, 162, 167);
  border-radius: 100px;
}


.fade-in {
  visibility: visible !important;
  opacity: 1 !important;
  transform: scale(1) !important;
  transition: all ease 0.5s;
}

[data-sal|="fade-up"] {
  opacity: 0 !important;
  ;
  transform: translateY(80px) !important;
  transition: opacity 0.9s ease, transform 0.9s ease !important;
  ;
}

[data-sal|="fade-up"].sal-animate {
  opacity: 1 !important;
  ;
  transform: translateY(0) !important;
  ;
}

.visible {
  opacity: 1;
  transition: all ease 0.3s;
}

.invisible {
  position: absolute;
  opacity: 0;
}


.build-out {
  /* transform: scaleY(0);
  transform-origin: 0 0; */
  height: 0%;
}

.build-in {
  /* transform: scaleY(1); */
  height: 100%;
}

.fade-in {
  visibility: visible !important;
  opacity: 1 !important;
  transform: scale(1) !important;
}

.fade-out {
  visibility: visible !important;
  opacity: 0 !important;
  transform: scale(0) !important;
}

.fade-up {
  opacity: 0 !important;
  transform: translateY(80px) !important;
  transition: opacity 0.4s ease, transform 0.4s ease !important;
}

.fade-up.fade-up-animate {
  opacity: 1 !important;
  transform: translateY(0) !important;
}